import React from "react";
import { navigate } from "gatsby";
import Metadata from "../components/Metadata";
// import Player from "../components/VideoNew";
import VideoHtml5 from "../components/VideoHtml5";

export default function VideoPage() {
    const finishVideo = () => {
        navigate('/next-page');
    };
    return (
      
      <div>
        <Metadata />
        <div>
          <VideoHtml5
            onVideoEnd={finishVideo}
          />
        </div>
      </div>
      
    );
}