import React, { useRef, useEffect, useState } from 'react';
import videoStyles from "../styles/components/video.module.scss";
import useWindowSize from "../utils/useWindowSize";
import videoLandscape from "../static/video/video-preview-handbraked.mp4";
import videoPortrait from "../static/video/video-mobile-handbraked.mp4";

export default function VideoHtml5({ onVideoEnd, ...props }) {
  const playerRef = useRef(null);
  const player = playerRef.current;
  const [videoSrc, setVideoSrc] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0)
  const { width } = useWindowSize();

  useEffect(() => {
    if(!!player) {
      setCurrentTime(player.currentTime ? player.currentTime : 0);
      setDuration(player.duration > 0 ? player.duration : 41.732);
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      setVideoSrc(videoPortrait);
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      setVideoSrc(videoLandscape);
    }
  }, [width]);

  return (
      <div className={videoStyles.videoWrapp}>
          <video 
            {...props}
            ref={playerRef}
            key={videoSrc}
            onEnded={onVideoEnd}
            controlsList="nodownload"
            disablePictureInPicture
            controls
            playsInline
            autoPlay
          >
              <source src={`${videoSrc}#t=${currentTime},${duration}`} type="video/mp4" />
              <track kind="captions" srcLang="en" />
          </video>
      </div>
  );
}